import React, { Component } from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Table from './vanpool-trips/Table';
import Calendar from './my-trips/Calendar';
import Expenses from './expense-recording/Expenses';
import Callable from '../shared/Callable';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import * as actionTypes from '../redux/actionTypes';
import RouteLeavingGuard from './RouteLeavingGuard';
import {
  RestrictTo,
  isAllowed,
  RestrictFrom
} from '../shared/permissions/RestrictTo';
import {
  PERMISSION_TRIP_RECORDING,
  PERMISSION_NTD_TRIP_RECORDING,
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_VANPOOL_TRIP_RECORDING,
  PERMISSION_SUBSCRIPTION_USER,
  PERMISSION_DRIVER,
  PERMISSION_COORDINATOR
} from '../shared/permissions/permissions';
import UserService from '../shared/user-service';
import analyticsService from '../shared/analytics/analytics-service';

const VANPOOL_TRIP_RECORDER = [
  [PERMISSION_VANPOOL_STATUS_RENTAL, PERMISSION_TRIP_RECORDING],
  [PERMISSION_ACCOUNT_DASHBOARD_ADMIN, PERMISSION_VANPOOL_TRIP_RECORDING]
];

const SUBSCRIPTION_VANPOOL_TRIP_RECORDER = [
  [PERMISSION_SUBSCRIPTION_USER, PERMISSION_DRIVER],
  [PERMISSION_SUBSCRIPTION_USER, PERMISSION_COORDINATOR]
];

export class TripRecording extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: 'current',
      hasPreviousData: false,
      beforeEleventh: moment().date() < 11,
      hasSetDefault: false,
      changeDetected:false
    };
    this.setHasPreviousData = this.setHasPreviousData.bind(this);
    this.changeMonths = this.changeMonths.bind(this);
  }

  componentDidMount() {
    this.props.getApprovalState();
    // analyticsService.analyticsProcessEvent({
    //   "event": "page_load",
    //   "page": {
    //     "page_name": "trip recording/vanpool trips"
    //   },
    // })
  }

  setHasPreviousData(hasPreviousData) {
    this.setState({ hasPreviousData }, () => this.setDefaultMonth(this.props));
  }

  setDefaultMonth(props) {
    if (this.state.hasSetDefault || this.props.approved === null) return;

    this.setState({
      month:
        !props.approved &&
        this.state.hasPreviousData &&
        this.state.beforeEleventh
          ? 'previous'
          : 'current',
      hasSetDefault: true
    });
  }

  changeMonths = () => {
    var month = this.state.month === 'previous' ? 'current' : 'previous';
    this.setState({ month: month, changeDetected:false});
    this.props.callChangeValidation();
  }

  render() {
    const { month, hasPreviousData, beforeEleventh, changeDetected } = this.state;
    const { approved, isSubscription } = this.props;
    const current = moment().format('MMMM');
    const previous = moment()
      .subtract(1, 'month')
      .format('MMMM');

    return (
      <div className='trip-container'>
      <div className="trip-recording">
        <div className="top-bar">
          <div className="month-nav" aria-labelledby="month navigation" role='group' tabIndex={0} aria-label="month navigation">
            {hasPreviousData ? (
              <button
                className={`prev-month ${
                  month === 'previous' ? 'selected' : ''
                }`}
                onClick={() => {
                  this.setState({ month: 'previous'});
                 // this.props.callChangeValidation();
              }}
              aria-label={`previous month ${previous}`}
              >
                {previous}
              </button>
            ) : null}
            <button
              className={`curr-month ${month === 'current' ? 'selected' : ''}`}
              onClick={() => {
                this.setState({ month: 'current' });
               // this.props.callChangeValidation();
                }}
                aria-label={`current month ${current}`}
            >
              {current}
            </button>
          </div>
          {
            !isSubscription ? 
            <RestrictTo roles={VANPOOL_TRIP_RECORDER} required={false}>
            <div className="switch-bar">
              <ul>
                <li>
                  <NavLink 
                    exact to="/trip-recording"
                    activeClassName="active"
                    onClick={() =>   analyticsService.analyticsProcessEvent({
                      "event": "trip_recording_click",
                      "context": {
                        "event_action": "select_vanpool_trips",
                      }
                    })}>
                      Vanpool Trips
                  </NavLink>
                </li>
                <RestrictFrom
                  roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
                  required={false}
                >
                  <li>
                    <NavLink
                      exact
                      to="/trip-recording/me"
                      activeClassName="active"
                    >
                      My Trips
                    </NavLink>
                  </li>
                </RestrictFrom>
                <RestrictTo
                  roles={[PERMISSION_NTD_TRIP_RECORDING]}
                  required={false}
                >
                  <li>
                    <NavLink
                      to="/trip-recording/expenses"
                      activeClassName="active"
                    >
                      Expenses
                    </NavLink>
                  </li>
                </RestrictTo>
              </ul>
            </div>
          </RestrictTo> 
          :
          null
          }

          <ConfirmationPopup
          id="checkvalidation"
          title="Unsaved changes"
          active={changeDetected}
          cancel={() => this.changeMonths()}
          confirm={() => this.setState({ changeDetected: false })}
          message={"Please save your changes before leaving the page."}
          cancelMessage="Cancel"
          confirmMessage="Ok"
          validationcss = 'true'
        />
      <RouteLeavingGuard
          when={this.props.changeValidation}
          navigate={path => this.props.history.push(path)}
          shouldBlockNavigation={location => {
             if (this.props.changeValidation) {
               if (location.pathname !== '/trip-recording') {
                analyticsService.analyticsProcessEvent({
                  "event": "modal_view_generic",
                  "context": {
                    "event_action": "trip recording/unsaved changes modal"
                  }
                });
                 return true
               }
             }
             return false
          }}
          handleValidation={() => {
            this.props.callChangeValidation();
           }}
          analyticsCancel = {() => analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
              "event_action": 'cancel',
              "event_detail": {
                  "modal": "trip recording/unsaved changes modal"
                  }
            }
          })}
          analyticsConfirm = {() => analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
              "event_action": 'ok',
              "event_detail": {
                  "modal": "trip recording/unsaved changes modal"
                  }
            }
          })}
       />
        </div>
        {month === 'previous' ? (
          beforeEleventh && !approved ? (
            <div className="message warning">
              Trips and expenses will be submitted on the 10th of{' '}
              {moment().format('MMMM')}. You are allowed to update this data at
              anytime prior.
            </div>
          ) : (
            <div className="message complete">
              Your {previous} trips and expenses have been submitted and can no
              longer be updated. If you have questions, please call{' '}
              <Callable phone="800 VAN 4 WORK" />.
            </div>
          )
        ) : null}
        <Switch>
          {isAllowed([
            [
              PERMISSION_VANPOOL_STATUS_RENTAL,
              PERMISSION_TRIP_RECORDING,
              PERMISSION_NTD_TRIP_RECORDING
            ],
            [
              PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
              PERMISSION_VANPOOL_TRIP_RECORDING,
              PERMISSION_NTD_TRIP_RECORDING
            ]
          ]) ? (
            <Route
              path="/trip-recording/expenses"
              render={() => (
                <Expenses
                  month={month}
                  beforeEleventh={beforeEleventh}
                  hasPreviousData={this.setHasPreviousData}
                  approved={approved}
                />
              )}
            />
          ) : null}
          {isAllowed([PERMISSION_ACCOUNT_DASHBOARD_ADMIN]) ? null : (
            <Route
              path="/trip-recording/me"
              render={() => (
                <Calendar
                  month={month}
                  hasPreviousData={this.setHasPreviousData}
                  beforeEleventh={beforeEleventh}
                  approved={approved}
                />
              )}
            />
          )}
          { 
          isAllowed(VANPOOL_TRIP_RECORDER) ||
          isAllowed(SUBSCRIPTION_VANPOOL_TRIP_RECORDER)
          ? (
            <Route
              exact={true}
              path="/trip-recording"
              render={() => (
                <Table
                  month={month}
                  hasPreviousData={this.setHasPreviousData}
                  beforeEleventh={beforeEleventh}
                  approved={approved}
                  history={this.props.history}
                />
              )}
            />
          ) : null}
          {/* {isAllowed(VANPOOL_TRIP_RECORDER) ? (
            <Route
              exact={true}
              path="/trip-recording"
              render={() => (
                <Table
                  month={month}
                  hasPreviousData={this.setHasPreviousData}
                  beforeEleventh={beforeEleventh}
                  approved={approved}
                />
              )}
            />
          ) : null} */}

          <Redirect
            to={
               isAllowed(VANPOOL_TRIP_RECORDER) ||
               isAllowed(SUBSCRIPTION_VANPOOL_TRIP_RECORDER)
                ? '/trip-recording'
                : '/trip-recording/me'
            }
          />
        </Switch>
      </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {...state.tripRecordingApproval, isSubscription: state.userAuth.isSubscription }
}

function mapDispatchToProps(dispatch) {
  return {
    getApprovalState() {
      if (
        UserService.getUserProfile().isInVanpool ||
        isAllowed([PERMISSION_ACCOUNT_DASHBOARD_ADMIN])
      ) {
        dispatch({ type: actionTypes.TRIP_RECORDING_APPROVAL });
      } else {
        dispatch({ type: actionTypes.MY_TRIPS_APPROVAL });
      }
    },
    callChangeValidation(){
      dispatch({ type: actionTypes.TRIP_RECORDING_STATUS_VALIDATE,data : {changeValidation : false} });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TripRecording);
